const URL_BASE = process.env.REACT_APP_API_URL;

export const get = async <T>(path:string): Promise<T> =>
	fetch(URL_BASE + path).then(response => {
		if (!response.ok) {
			throw new Error(response.statusText + ' ' + response.body);
		}
		return response.json() as Promise<T>;
	});

const cache = new Map<string, any>();
const maxCacheSize = 100;

export const getCached = async <T>(path:string): Promise<T> => {
	const cached = cache.get(path);
	if (cached === undefined) {
		if (cache.size === maxCacheSize)
			cache.delete(cache.keys().next().value);
		const fetched = get(path);
		cache.set(path, fetched);
		return fetched as Promise<T>;
	} else
		return cached;
};

export const postJson = async <T>(path:string, data:any): Promise<T> =>
	fetch(
		URL_BASE + path,
		{
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(data)
		}
	).then(response => {
		if (!response.ok) {
			throw new Error(response.statusText + ' ' + response.body);
		} else if (response.status === 200) {
			return response.json() as Promise<T>;
		} else return Promise.resolve(null as unknown as Promise<T>);
	});

export const postPlain = async <T>(path:string, data:any): Promise<T> =>
	fetch(
		URL_BASE + path,
		{
			method: 'POST',
			headers: {'Content-Type': 'text/plain'},
			body: data
		}
	).then(response => {
		if (!response.ok) {
			throw new Error(response.statusText + ' ' + response.body);
		} else if (response.status === 200) {
			return response.json() as Promise<T>;
		} else return Promise.resolve(null as unknown as Promise<T>);
	});

export const postEmpty = async <T>(path:string): Promise<T> =>
	fetch(
		URL_BASE + path,
		{
			method: 'POST',
			headers: {'Content-Type': 'text/plain'}
		}
	).then(response => {
		if (!response.ok) {
			throw new Error(response.statusText + ' ' + response.body);
		} else if (response.status === 200) {
			return response.json() as Promise<T>;
		} else return Promise.resolve(null as unknown as Promise<T>);
	});