import { useState, useEffect } from 'react';
import { ActionsFormItem } from 'component/ActionsFormItem';
import { Action, Actions, submitActions } from 'service/ActionsService';
import { Book } from 'model/Book';

interface Props {
	location: string | null;
	books: Book[];
}

const styleNotLoaded = {...{}};
const styleLoaded = {...{opacity: 1}};

export const ActionsForm = (props: Props) => {

	const [loaded, setLoaded] = useState<boolean>(false);
	useEffect(() => {
		setTimeout(() => setLoaded(true), 20);
	}, []);
	
	const [actions, setActions] = useState<Actions>({});
	const [buttonText, setButtonText] = useState<string>('Submit');
	const [submitted, setSubmitted] = useState<boolean>(false);

	
	const changeAction = (id:number) => {
		return (action: Action) => {
			setActions({...actions, [id]:action});
		};
	};
	
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let containsBorrow = false;
		let containsReturn = false;
		Object.values(actions).forEach(action => {
			if (action === 'borrow')
				containsBorrow = true;
			else if (action === 'return')
				containsReturn = true;
		});
		if (containsBorrow || containsReturn)
		{
			setButtonText(containsBorrow ? 'Enjoy!' : 'Thank you!');
			setSubmitted(true);
			submitActions(actions, props.location);
		}
	};
	
	return (
		<form onSubmit={handleSubmit}>
			<section className="actions-section" style={loaded ? styleLoaded : styleNotLoaded}>
				<div className='let-us-know'>Let us know how you're using the library today!</div>
				<div className="actions-container">
				{props.books?.map(book =>
					<ActionsFormItem title={book.title} author={book.author} id={book.id} action={actions[book.id]} changeAction={changeAction(book.id)} key={book.id} submitted={submitted} />
				)}
				</div>
				<button type="submit" className="actions-button" disabled={submitted}>{buttonText}</button>
			</section>
		</form>
	);
}
