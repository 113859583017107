import { MainSection } from 'component/MainSection';
import { JoinMailingListForm } from 'component/JoinMailingListForm';

const location: string | null = new URLSearchParams(document.location.search).get('location');

export const App = () => {
	
	window.history.replaceState(null, "", "/");

	return (
		<>
			<MainSection location={location} />
			<JoinMailingListForm />
		</>
	);
}
