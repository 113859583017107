import { Action } from 'service/ActionsService';

interface Props {
	title: string;
	author: string;
	id: number;
	action: Action;
	changeAction: (action: Action) => void;
	submitted: boolean;
}

export const ActionsFormItem = (props:Props) => {
	
	const borrowId = 'borrow-' + props.id;
	const returnId = 'return-' + props.id;
	
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const nowChecked = event.target.checked;
		const borrowChanged = event.target.value.startsWith('borrow');
		const action = !nowChecked ? null : (borrowChanged ? 'borrow' : 'return');
		props.changeAction(action);
	}
	
	return (
		<>
			<div className="actions-item-title-and-author">
				<span className="actions-item-title">{props.title}</span>
				<span>{props.author}</span>
			</div>
			<div className="actions-item-checkboxes">
				<div className="actions-item-checkbox-and-label">
					<input type="checkbox" value={borrowId} id={borrowId} checked={props.action === 'borrow'} onChange={handleChange} disabled={props.submitted} />
					<label htmlFor={borrowId} className="actions-item-checkbox-label">Borrowing</label>
				</div>
				<div className="actions-item-checkbox-and-label">
					<input type="checkbox" value={returnId} id={returnId} checked={props.action === 'return'} onChange={handleChange} disabled={props.submitted} />
					<label htmlFor={returnId} className="actions-item-checkbox-label">Returning</label>
				</div>
			</div>
		</>
	);
}
