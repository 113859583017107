import { postJson } from 'service/ApiService';

export type Action = 'borrow' | 'return' | null;
export interface Actions {
	[id: number]: Action;
}

export const submitActions = async (actions:Actions, location:string | null): Promise<void> => {
	const withoutNulls = Object.fromEntries(Object.entries(actions).filter(([_, val]) => !!val));
	postJson("/api/actions?location=" + location, withoutNulls);
}
