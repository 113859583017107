import { submitUnlock } from 'service/UnlockService';
import { useState } from 'react';

interface Props {
	location: string | null;
	validLocation: boolean;
	onUnlocked: () => void;
}

const buttonStyleNotFading = {...{}};
const buttonStyleFading = {...{opacity: 0, transform: 'rotate3d(0, 1, 0, -90deg)'}};

export const UnlockOrInfo = (props:Props) => {
	
	const [buttonText, setButtonText] = useState('Let me in!');
	const [fading, setFading] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);
	
	const unlock = () => {
		setSubmitted(true);
		submitUnlock(props.location);
		let timeout = 0;
		let interval = 800;
		setTimeout(() => setButtonText('Door opening'), 0);
		setTimeout(() => setButtonText('Door opening.'), timeout += interval);
		setTimeout(() => setButtonText('Door opening..'), timeout += interval);
		setTimeout(() => setButtonText('Door opening...'), timeout += interval);
		setTimeout(() => setButtonText('Door opening....'), timeout += interval);
		setTimeout(() => setFading(true), timeout += interval);
		setTimeout(() => props.onUnlocked(), timeout += 500);
	};
	
	const button = (
		<div className="welcome-button-container">
			<button
				onClick={unlock}
				className="welcome-button"
				style={fading ? buttonStyleFading : buttonStyleNotFading}
				disabled={submitted}
				type="button">
				{buttonText}
			</button>
		</div>
	);
	
	const information = (
		<p className="welcome-p">
			Visit a Little Board Book Library location and scan its QR code to open the box.
		</p>
	);
	
	return props.validLocation ? button : information;
}
