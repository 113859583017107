import { useState, useRef } from 'react';
import { submitEmailAddress } from 'service/MailingListService';
	
const buttonStyleNotJoined = {...{}};
const buttonStyleJoined = {...{pointerEvents: 'none'}};
const inputStyleNotJoined = {...{}};
const inputStyleJoined = {...{pointerEvents: 'none'}};
const textStyleJoined = {...{display:'none'}};
const textStyleNotJoined = {...{display:'inherit'}};
const imageStyleJoined1 = {...{display:'inherit'}};
const imageStyleJoined2 = {...{display:'inherit'}, ...{transform:'translate(0,0) scale(1)'}, ...{opacity:1}};
const imageStyleNotJoined = {...{display:'none'}};

export const JoinMailingListForm = () => {
	
	const [emailAddress, setEmailAddress] = useState('');
	const [joined, setJoined] = useState(false);
	const [readyToAnimate, setReadyToAnimate] = useState(false);
	
	const emailInput = useRef<HTMLInputElement>(null);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		!!emailInput?.current && emailInput.current.blur();
		setJoined(true);
		setTimeout(() => {setReadyToAnimate(true)}, 20);
		submitEmailAddress(emailAddress);
	};
	
	return (
		<div className="join-container">
			<p className="join-cta">
				Join our mailing list to find out when new titles are added.
			</p>
			<form onSubmit={handleSubmit}>
				<div className="join-form-container">
					<input
						ref={emailInput}
						value={emailAddress}
						onChange={(event) => setEmailAddress(event.target.value)}
						className="join-text-input"
						style={joined ? inputStyleJoined : inputStyleNotJoined}
						type="email" maxLength={128} placeholder="Email" autoCorrect="off" autoCapitalize="off" spellCheck="false"/>
					<button
						type="submit"
						className="join-button"
						style={joined ? buttonStyleJoined : buttonStyleNotJoined}>
						<span style={joined ? textStyleJoined : textStyleNotJoined}>Join</span>
						<img
							src="images/check.svg"
							className="join-checkmark"
							alt=""
							style={joined ? (readyToAnimate ? imageStyleJoined2 : imageStyleJoined1) : imageStyleNotJoined} />
					</button>
				</div>
			</form>
		</div>
	);
}
