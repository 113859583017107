import { useState, useEffect } from 'react';
import { isValidLocation } from 'service/UnlockService';
import { UnlockOrInfo } from 'component/UnlockOrInfo';
import { ActionsForm } from 'component/ActionsForm';
import { getBooks } from 'service/BookService';
import { Book } from 'model/Book';

interface Props {
	location: string | null;
}

export const MainSection = (props:Props) => {
	
	const [unlocked, setUnlocked] = useState(false);
	const onUnlocked = () => setUnlocked(true);
	
	const [validLocation, setValidLocation] = useState(false);
	useEffect(() => {
		isValidLocation(props.location).then((result:boolean) => setValidLocation(result));
	}, [props.location]);
	
	const [books, setBooks] = useState<Book[]>([]);
	useEffect(() => {
		if (validLocation)
			getBooks().then((result: Book[]) => setBooks(result));
	}, [validLocation]);
	
	return (
		<section className="welcome-section">
			<div className="welcome-logo-container">
				<img className="welcome-logo-image" src="images/logo.svg" alt="Little Board Book Library Logo"/>
			</div>
			<h1 className="welcome-header">
				Welcome to the Little Board Book Library
			</h1>
			<p className="welcome-p">
				We hope you enjoy our curated selection of classic board books.
			</p>
			<p className="welcome-p">
				Borrow up to five books at a time and return them when you're finished.
			</p>
			{!unlocked && <UnlockOrInfo location={props.location} validLocation={validLocation} onUnlocked={onUnlocked} />}
			{unlocked && <ActionsForm location={props.location} books={books} />}
		</section>
	)
}
